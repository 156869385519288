import { saveAddiction } from "./utilities";

const addictionChanges = document.querySelectorAll('[data-behaviour*="addiction-change"]');
if (addictionChanges.length > 0) {
    addictionChanges.forEach((addictionChange) => {
        addictionChange.addEventListener('change', () => {
            saveAddiction(addictionChange.value);
        });
    });
}
